import * as React from "react"

import '../../components/Contacts/Listing/contacts.css'
import PrivateRoute from "../../components/privateRoute";
import ContactList from "../../components/Contacts/Listing/contactList";
import ReduxWrapper from "../../redux/reduxWrapper";

const Contacts = (props) => {
    return (
        <PrivateRoute component={ContactList} location={props.location} />
    )
}


const WrappedPage = (props) => (
    <ReduxWrapper element={<Contacts {...props} />} />
)
export default WrappedPage