import * as React from "react";

import {StaticImage} from "gatsby-plugin-image";

const ContactSharedUsers = () => {

    return (
        <div className="SwiftCloudSharedWithUsers">
            <ul>
                <li>
                    <a href="#" data-bs-toggle="tooltip" title="Rosa">
                        <StaticImage src="./../../../images/Rosa.jpg" alt=''/>
                    </a>
                </li>
                <li>
                    <a href="#" data-bs-toggle="tooltip" title="Tejas">
                        <StaticImage src="./../../../images/tejas.png" alt=''/>
                    </a>
                </li>
                <li>
                    <a href="#" data-bs-toggle="tooltip" title="Susan">
                        <StaticImage src="./../../../images/susan.jpg" alt=''/>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default ContactSharedUsers;