import * as React from "react";

const ContactTags = ({ contact }) => {

    return (
        <div className="SwiftCloudTableTags">
            <ul>
                {contact.tags.map( (tagDetail) => (
                    <li key={tagDetail.tag}>
                        <a href="#" data-tagname="Designer">{tagDetail.tag}</a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ContactTags;