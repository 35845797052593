import * as React from "react";
import {Link} from "gatsby";
import TimeAgo from "timeago-react";
import {timeFormatter} from "../../../services/timeHelper";
import ContactQuickTools from "./contactQuickTools";
import ContactSharedUsers from "./contactSharedUsers";
import ContactTags from "./contactTags";

const ContactListRow = ({contact}) => {
    const contactId = 'C_ID' + contact.id;
    return (
        <tr>
            <td className="tableColChkDel">
                <div className="tableColChkDelBox">
                    <input type="checkbox" name="fileid[]" id={contactId} className="css-checkbox"/>
                    <label htmlFor={contactId} className="css-checkbox-label" />
                    <span className="tableColChkSpan btn-secondary">
                        <i className="bi bi-building" />
                    </span>
                </div>
            </td>
            <td className="nowrap">
                { contact.name && <Link to={`detail/${contact.id}`}>
                    { contact.name }
                </Link>}

                { contact.companyName && <span className="contactListCompany">
                    { contact.companyName }
                </span>}

                <ContactSharedUsers />

                <ContactQuickTools contact={contact} />

                { contact.tags.length > 0 && <ContactTags contact={contact} /> }

            </td>
            <td className="d-none d-md-table-cell">
                <TimeAgo datetime={timeFormatter(contact.updatedAt)} />
            </td>
            <td className="text-end tableColAction d-none d-lg-table-cell">
                <a href="#" className="btn btn-delete"
                   data-bs-toggle="tooltip" data-placement="bottom"
                   data-animation="false" title="Delete">
                    <i className="bi bi-trash-fill" />
                </a>
            </td>
        </tr>
    );
}

export default ContactListRow;