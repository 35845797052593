
export const timeFormatter = ( timeString ) => {
    // date format require 2022-09-05T07:16:20+00:00
    let date = new Date(timeString);

    // return 2022-8-8 13:54:59
    return date.getFullYear() +'-'+ (date.getMonth()+1) +'-'+ date.getDate()  +' '+ date.getHours() +':'+ date.getMinutes() +':'+ date.getSeconds();
}

export const createDateObject = ( timeString ) => {
    return new Date(timeString.date)
}