import * as React from "react"
import {useRef} from "react";
import Axios from "axios";
import clsx from "clsx";
import Layout from "../../Layout/layout";
import {getAccessToken} from "../../../services/auth";
import ContactLazyLoad from "./contactLazyLoad";
import ContactListRow from "./contactListRow";

import '../../../css/jquery_dataTables.min.css';
import './contacts.css'
import {Link} from "gatsby";

const ContactList = (props) => {

    const triggerRef = useRef(null);
    const onGrabData = (currentPage, pages_count) => {
        return new Promise(async (resolve) => {
            let data = {
                "items" : [],
                "pages_count" : pages_count
            };

            if (pages_count === null || currentPage <= pages_count ) {
                data  = await Axios.get(
                    `${process.env.GATSBY_BACKEND_API_URL}/contact/`, {
                        headers: {
                            'Authorization': 'Bearer ' + getAccessToken(),
                        },
                        params : {
                            page : currentPage,
                        }
                    }
                ).then( (response) => {
                    return response.data;
                });
            }
            resolve(data, pages_count);
        });
    };

  const { data, loading } = ContactLazyLoad({ triggerRef, onGrabData });

  return (
      <Layout>
          <section className="mainSection">
              <div className="container-fluid">
                  <div className="row g-0">
                      <div className="col tableContainer">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="float-start">
                                      <div className="tableSearchContainer">
                                          <input type="search" className="form-control" placeholder="Search contacts" />
                                      </div>
                                  </div>
                                  <div className="float-end">
                                      <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                                          <li className="nav-item">
                                              <div className="btn-group btnAddNewDropdown" role="group">
                                                  <button id="btnGroupDrop1" type="button"
                                                          className="btn btn-primary dropdown-toggle"
                                                          data-bs-toggle="dropdown" aria-expanded="false">
                                                      <i className="bi bi-plus-lg" /> <span className="d-none d-md-inline">Add New</span>
                                                  </button>
                                                  <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                      <li>
                                                          <Link to={"add"} className="dropdown-item" >
                                                              <i className="bi bi-person-fill" /> Person
                                                          </Link>
                                                      </li>
                                                      <li>
                                                          <Link to={"add"} className="dropdown-item" >
                                                              <i className="bi bi-people-fill" /> Tribe
                                                          </Link>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                              <a className="nav-link active" id="contacts-tab" data-bs-toggle="tab"
                                                 data-bs-target="#contacts-tab-pane" role="tab"
                                                 aria-controls="contacts-tab-pane" aria-selected="false"><span
                                                  data-bs-toggle="tooltip" title="Contacts">
                                                  <i className="bi bi-person-fill" /></span></a>
                                          </li>
                                          <li className="nav-item nav-item-trash" role="presentation">
                                              <a className="nav-link" id="contact-tab" data-bs-toggle="tab"
                                                 data-bs-target="#contact-tab-pane" role="tab"
                                                 aria-controls="contact-tab-pane" aria-selected="false">
                                                  <span data-bs-toggle="tooltip" title="Trash">
                                                      <i className="bi bi-trash-fill" />
                                                 </span>
                                              </a>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                              <div className="col-lg-12 tableFadeOut">
                                  <div className="tab-content" id="contactListTabs">
                                      <div className="tab-pane fade show active" id="contacts-tab-pane" role="tabpanel"
                                           aria-labelledby="contacts-tab" tabIndex="0">
                                          <table id="tblContacts" className="table dataTable" cellSpacing="0"
                                                 width="100%">
                                              <thead>
                                              <tr>
                                                  <th width="5%">&nbsp;</th>
                                                  <th width="70%" className="nowrap">
                                                      <div className="btn-group btnAddNewDropdown filterContactDropdown"
                                                           role="group">
                                                          <button type="button"
                                                                  className="btn btn-secondary dropdown-toggle"
                                                                  data-bs-toggle="dropdown" aria-expanded="false">
                                                              <i className="bi bi-bullseye" />
                                                              <span className="d-none d-md-inline">Everyone
                                                              </span>
                                                          </button>
                                                          <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                              <li>
                                                                  <a className="dropdown-item" href="#">
                                                                      <i className="bi bi-people-fill" /> Local Friends
                                                                  </a>
                                                              </li>
                                                              <li><a className="dropdown-item" href="#">
                                                                  <i className="bi bi-person-fill" /> People</a>
                                                              </li>
                                                              <li>
                                                                  <a className="dropdown-item" href="#">
                                                                      <i className="bi bi-building" /> Companies
                                                                  </a>
                                                              </li>
                                                              <li>
                                                                  <a className="dropdown-item" href="#">
                                                                      <i className="bi bi-person-check-fill" /> Following
                                                                  </a>
                                                              </li>
                                                              <li>
                                                                  <a className="dropdown-item" href="#">
                                                                      <i className="bi bi-people-fill" /> Tribes
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                  </th>
                                                  <th width="20%" className="d-none d-md-table-cell">Last Activity</th>
                                                  <th width="5%" className="text-end d-none d-lg-table-cell" />
                                              </tr>
                                              <tr className="d-none table_action_container">
                                                  <td colSpan="4" className="text-left">
                                                      <div className="batch-action-container">
                                                          <button className="btn btn-trash">
                                                              <i className="bi bi-trash-fill" /> Move to Trash
                                                          </button>
                                                      </div>
                                                  </td>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {data.map((contact, index) => {
                                                  return (
                                                      <ContactListRow contact={contact} key={`contact_row_${index}`}/>
                                                  )}
                                              )}
                                              </tbody>
                                          </table>
                                      </div>
                                      <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel"
                                           aria-labelledby="contact-tab" tabIndex="0">
                                          <table id="tblTrashContacts" className="table dataTable" cellSpacing="0"
                                                 width="100%">
                                              <thead>
                                              <tr>
                                                  <th width="5%">&nbsp;</th>
                                                  <th width="70%">
                                                      <select id="contactTrashFilter">
                                                          <option defaultValue="Everyone" data-icon="bi-bullseye">Everyone </option>
                                                          <option defaultValue="Local Friends" data-icon="bi-people">Local Friends </option>
                                                          <option defaultValue="Local Friends" data-icon="bi-person">People </option>
                                                          <option defaultValue="Local Friends" data-icon="bi-building">Companies </option>
                                                          <option defaultValue="Following" data-icon="bi-person-check-fill">Following </option>
                                                          <option defaultValue="Tribes" data-icon="bi-people">Tribes</option>
                                                      </select>
                                                      <button type="button"
                                                              className="btn btn-sm btnTHSoryByTags float-end me-3"
                                                              data-bs-toggle="modal"
                                                              data-bs-target="#ContactsSortByTagModal"
                                                              title="Advanced Tag Search">
                                                          <span data-bs-toggle="tooltip" data-placement="bottom" data-animation="false" title="Advanced Tag Search">
                                                              <i className="fa fa-tag" />
                                                          </span>
                                                      </button>
                                                  </th>
                                                  <th width="20%" className="d-none d-md-table-cell">Last Activity</th>
                                                  <th width="5%" className="text-end d-none d-lg-table-cell"></th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              <tr>
                                                  <td className="tableColChkDel">
                                                      <div className="tableColChkDelBox">
                                                          <input type="checkbox" name="fileid[]" id="TC_ID1"
                                                                 className="css-checkbox"/>
                                                          <label htmlFor="TC_ID1" className="css-checkbox-label" />
                                                          <span className="tableColChkSpan btn-secondary">
                                                              <i className="bi bi-person-fill" />
                                                          </span>
                                                      </div>
                                                  </td>
                                                  <td className="nowrap">
                                                      <a href="contact_detail.html">SwiftCloud Support</a>
                                                      <span className="contactListCompany">SwiftCloud</span>
                                                      <div className="contactListQuickTools">
                                                          <a className="contactEmail" href="#" data-bs-toggle="tooltip"
                                                             data-placement="top"
                                                             title="SwiftCloudSupport@domain.com"><i
                                                              className="bi bi-envelope-fill"></i></a>
                                                          <a className="contactPhoneSMS" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="SMS to 9876543210"><i
                                                              className="bi bi-phone-fill"></i></a>
                                                          <a className="contactPhoneCall" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="Call to 9876543210"><i
                                                              className="bi bi-telephone-fill"></i></a>
                                                          <a className="contactAddress" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="New street"><i
                                                              className="bi bi-geo-alt-fill"></i></a>
                                                      </div>
                                                      <div className="SwiftCloudTableTags">
                                                          <ul>
                                                              <li><a href="#"
                                                                     data-tagname="Designer">Designer</a></li>
                                                              <li><a href="#"
                                                                     data-tagname="Support">Support</a></li>
                                                          </ul>
                                                      </div>
                                                  </td>
                                                  <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2022-05-01"></time>
                                                  </td>
                                                  <td className="text-end tableColAction d-none d-lg-table-cell">
                                                      <a href="#" className="btn btn-delete"
                                                         data-bs-toggle="tooltip" data-placement="bottom"
                                                         data-animation="false" title="Delete"><i
                                                          className="bi bi-trash-fill"></i></a>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td className="tableColChkDel">
                                                      <div className="tableColChkDelBox">
                                                          <input type="checkbox" name="fileid[]" id="TC_ID2"
                                                                 className="css-checkbox"/>
                                                          <label htmlFor="TC_ID2"
                                                                 className="css-checkbox-label"></label>
                                                          <span className="tableColChkSpan btn-secondary"><i
                                                              className="bi bi-person-fill"></i></span>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <a href="contact_detail.html">Roger V.</a>
                                                      <span className="contactListCompany">SwiftCloud</span>
                                                      <div className="contactListQuickTools">
                                                          <a className="contactEmail" href="#" data-bs-toggle="tooltip"
                                                             data-placement="top"
                                                             title="SwiftCloudSupport@domain.com"><i
                                                              className="bi bi-envelope-fill"></i></a>
                                                          <a className="contactPhoneSMS" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="SMS to 9876543210"><i
                                                              className="bi bi-phone-fill"></i></a>
                                                          <a className="contactPhoneCall" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="Call to 9876543210"><i
                                                              className="bi bi-telephone-fill"></i></a>
                                                          <a className="contactAddress" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="New street"><i
                                                              className="bi bi-geo-alt-fill"></i></a>
                                                      </div>
                                                      <div className="SwiftCloudTableTags">
                                                          <ul>
                                                              <li><a href="#"
                                                                     data-tagname="Support">Support</a></li>
                                                          </ul>
                                                      </div>
                                                  </td>
                                                  <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2022-05-15"></time>
                                                  </td>
                                                  <td className="text-end tableColAction d-none d-lg-table-cell">
                                                      <a href="#" className="btn btn-delete"
                                                         data-bs-toggle="tooltip" data-placement="bottom"
                                                         data-animation="false" title="Delete">
                                                          <i className="bi bi-trash-fill" />
                                                      </a>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td className="tableColChkDel">
                                                      <div className="tableColChkDelBox">
                                                          <input type="checkbox" name="fileid[]" id="TC_ID3"
                                                                 className="css-checkbox"/>
                                                          <label htmlFor="TC_ID3" className="css-checkbox-label"/>
                                                          <span className="tableColChkSpan btn-secondary">
                                                              <i className="bi bi-person-fill" />
                                                          </span>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <a href="contact_detail.html">Tejas H.</a>
                                                      <span className="contactListCompany">CryptousWeb</span>
                                                      <div className="contactListQuickTools">
                                                          <a className="contactEmail" href="#" data-bs-toggle="tooltip"
                                                             data-placement="top"
                                                             title="SwiftCloudSupport@domain.com">
                                                              <i className="bi bi-envelope-fill" />
                                                          </a>
                                                          <a className="contactPhoneSMS" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="SMS to 9876543210">
                                                              <i className="bi bi-phone-fill" />
                                                          </a>
                                                          <a className="contactPhoneCall" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="Call to 9876543210">
                                                              <i className="bi bi-telephone-fill" />
                                                          </a>
                                                      </div>
                                                      <div className="SwiftCloudTableTags">
                                                          <ul>
                                                              <li><a href="#"
                                                                     data-tagname="UI/UX">UI/UX</a></li>
                                                              <li><a href="#"
                                                                     data-tagname="Design">Design</a></li>
                                                              <li><a href="#"
                                                                     data-tagname="WordPress">WordPress</a></li>
                                                          </ul>
                                                      </div>
                                                  </td>
                                                  <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2022-05-25" />
                                                  </td>
                                                  <td className="text-end tableColAction d-none d-lg-table-cell">
                                                      <a href="#" className="btn btn-delete"
                                                         data-bs-toggle="tooltip" data-placement="bottom"
                                                         data-animation="false" title="Delete">
                                                          <i className="bi bi-trash-fill" />
                                                      </a>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td className="tableColChkDel">
                                                      <div className="tableColChkDelBox">
                                                          <input type="checkbox" name="fileid[]" id="TC_ID4"
                                                                 className="css-checkbox"/>
                                                          <label htmlFor="TC_ID4" className="css-checkbox-label" />
                                                          <span className="tableColChkSpan btn-secondary">
                                                              <i className="bi bi-person-fill" />
                                                          </span>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <a href="contact_detail.html">Zyndall</a>
                                                      <div className="contactListQuickTools">
                                                          <a className="contactEmail" href="#" data-bs-toggle="tooltip"
                                                             data-placement="top"
                                                             title="SwiftCloudSupport@domain.com">
                                                              <i className="bi bi-envelope-fill" />
                                                          </a>
                                                          <a className="contactPhoneCall" href="#"
                                                             data-bs-toggle="tooltip" data-placement="top"
                                                             title="Call to 9876543210">
                                                              <i className="bi bi-telephone-fill" />
                                                          </a>
                                                      </div>
                                                      <div className="SwiftCloudTableTags">
                                                          <ul>
                                                              <li><a href="#"
                                                                     data-tagname="Buyer">Buyer</a></li>
                                                          </ul>
                                                      </div>
                                                  </td>
                                                  <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2022-05-25" />
                                                  </td>
                                                  <td className="text-end tableColAction d-none d-lg-table-cell">
                                                      <a href="#" className="btn btn-delete"
                                                         data-bs-toggle="tooltip" data-placement="bottom"
                                                         data-animation="false" title="Delete">
                                                          <i className="bi bi-trash-fill" />
                                                      </a>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td className="tableColChkDel">
                                                      <div className="tableColChkDelBox">
                                                          <input type="checkbox" name="fileid[]" id="TC_ID5"
                                                                 className="css-checkbox"/>
                                                          <label htmlFor="TC_ID5" className="css-checkbox-label" />
                                                          <span className="tableColChkSpan">
                                                              <img src="images/bachchan-amitabh-image.jpg"/>
                                                          </span>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <a href="contact_detail.html">Amitabh</a>
                                                      <div className="contactListQuickTools">
                                                          <a className="contactEmail" href="#" data-bs-toggle="tooltip"
                                                             data-placement="top"
                                                             title="SwiftCloudSupport@domain.com">
                                                              <i className="bi bi-envelope-fill" />
                                                          </a>
                                                      </div>
                                                      <div className="SwiftCloudTableTags">
                                                          <ul>
                                                              <li><a href="#"
                                                                     data-tagname="Actor">Actor</a></li>
                                                              <li><a href="#"
                                                                     data-tagname="Bollywood">Bollywood</a></li>
                                                          </ul>
                                                      </div>
                                                  </td>
                                                  <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2021-07-25" />
                                                  </td>
                                                  <td className="text-end tableColAction d-none d-lg-table-cell">
                                                      <a href="#" className="btn btn-delete"
                                                         data-bs-toggle="tooltip" data-placement="bottom"
                                                         data-animation="false" title="Delete">
                                                          <i className="bi bi-trash-fill" />
                                                      </a>
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
          </section>
      </Layout>
  )
}

export default ContactList
