import * as React from "react";

const ContactQuickTools = ({ contact }) => {

    return (
        <div className="contactListQuickTools">
            {contact.emails && contact.emails.map( (emailData) => {
                return (
                    <a className="contactEmail" href="#" data-bs-toggle="tooltip"
                       data-placement="top" title={emailData.email}
                       key={`contact_email_${contact.id}_${emailData.email}`}>
                        <i className="bi bi-envelope-fill" />
                    </a>
                )
            })}

            {contact.phones && contact.phones.map( (phoneData) => {
                return (
                    <React.Fragment key={`contact_phone_${contact.id}_${phoneData.phone}`}>

                        { phoneData.type !== 'Fax' &&
                        <a className="contactPhoneSMS" href="#"
                           data-bs-toggle="tooltip" data-placement="top"
                           title={`SMS to ${phoneData.phone}`}>
                            <i className="bi bi-phone-fill" />
                        </a>
                        }

                        { phoneData.type !== 'Fax' &&
                        <a className="contactPhoneCall" href="#"
                           data-bs-toggle="tooltip" data-placement="top"
                           title={`Call to ${phoneData.phone}`}>
                            <i className="bi bi-telephone-fill" />
                        </a>
                        }

                    </React.Fragment>
                )
            })}

            {contact.metaJson.address && <React.Fragment>

                <a className="contactAddress" href="#"
                   data-bs-toggle="tooltip" data-placement="top"
                   title={contact.metaJson.address.address}>
                    <i className="bi bi-geo-alt-fill" />
                </a>

                </React.Fragment>
            }

        </div>
    )
}

export default ContactQuickTools;