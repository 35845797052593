import React from "react"
import { navigate } from "gatsby"
import {isBrowser, isLoggedIn} from "../services/auth"

const PrivateRoute = ({
          component: Component,
          location,
          ...rest
}) => {
    if(isBrowser()) {
        if (!isLoggedIn() && location.pathname !== `/login`) {
            navigate('/');
        } else {
            return <Component {...rest} />
        }
    }

    return ( <React.Fragment /> )
}

export default PrivateRoute